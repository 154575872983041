html{
  min-height: 100vh;
  min-width: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.player-wrapper {
  width: auto !important;
  height: auto !important;
}
.react-player {
  position: relative;
}

.react-player > div {
  position: absolute;
}

.inline-block {
  display: inline-block;
}

.justify-center {
  justify-content: center;
}

.align-middle {
  vertical-align: middle;
}

/* MARGINS and PADDINGS */
.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.m-0 {
  margin: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-4 {
   margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.w-4 {
  width: 1rem;
}

.h-4 {
  height: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.h-6 {
  height: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.h-8 {
  height: 2rem;
}

.lh-1 {
  line-height: 1;
}

.margin-horizontal-625 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.margin-horizontal-9375 {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}

.margin-top-05 {
  margin-top: 0.5rem !important;
}

.margin-right-05 {
  margin-right: 0.5rem !important;
}

.padding-top-05 {
  padding-top: 0.5rem !important;
}

.padding-bottom-05 {
  padding-bottom: 0.5rem !important;
}

.padding-horizontal-05 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.padding-vertical-05 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-xs {
  font-size: .75rem;
}

.text-sm {
  font-size: .875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.375rem;
}

.text-3xl {
  font-size: 1.5rem;
}

.text-4xl {
  font-size: 1.875rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-black {
  color: #22292f;
}

.text-grey-darkest {
  color: #3d4852;
}

.text-grey-darker {
  color: #4c4c4c;
}

.text-grey-dark {
  color: #8795a1;
}

.text-grey {
  color: #b8c2cc;
}

.text-grey-light {
  color: #dae1e7;
}

.text-grey-lighter {
  color: #f3f0ec;
}

.text-grey-lightest {
  color: #f8fafc;
}

.text-white {
  color: #fff;
}

.text-blue-darkest {
  color: #12283a;
}

.text-blue-darker {
  color: #1c3d5a;
}

.text-blue-dark {
  color: #092a5e;
}

.text-blue {
  color: #219ed9;
}

.text-blue-light {
  color: #70cbf4;
}

.text-blue-lighter {
  color: #c2e6fa;
}

.text-blue-lightest {
  color: #e2f3fe;
}

.text-red {
  color: #c02;
}

.text-smoke {
  color: rgba(0, 0, 0, .5);
}

.text-smoke-dark {
  color: rgba(0, 0, 0, .8);
}

.font-bold {
  font-weight: bold;
}

.bg-black {
  background-color: #22292f;
}

.bg-grey-darkest {
  background-color: #3d4852;
}

.bg-grey-darker {
  background-color: #4c4c4c;
}

.bg-grey-dark {
  background-color: #8795a1;
}

.bg-grey {
  background-color: #b8c2cc;
}

.bg-grey-light {
  background-color: #dae1e7;
}

.bg-grey-lighter {
  background-color: #f3f0ec;
}

.bg-grey-lightest {
  background-color: #f8fafc;
}

.bg-white {
  background-color: #fff;
}

.bg-blue {
  background-color: #219ed9;
}

.bg-blue-lighter {
  background-color: #c2e6fa;
}

.bg-red {
  background-color: #c02;
}

.bg-smoke-light {
  background-color: rgba(255, 255, 255, .8);
}

.bg-smoke {
  background-color: rgba(0, 0, 0, .5);
}

.bg-smoke-dark {
  background-color: rgba(0, 0, 0, .8);
}

.bg-transparent{
  background-color: transparent;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.border-b {
  border-bottom: 1px solid #93999f;
}

.border-red {
  border-color: #c02;
}

.border-dark-blue {
  border-color: #092a5e;
}

.select-none {
  user-select: none;
}

/* form elements */
.input-field {
    margin: 0;
}

/* bookings table */
.bookings-table tbody, .bookings-table thead {
  border: none;
  font-weight: normal;
}

.bookings-table thead tr {
  background-color: #092a5e;
  color: white;
}

.bookings-table tbody tr:nth-child(2n) {
  background-color: #E2F3FF;
}

.bookings-table tbody tr:nth-child(2n+1) {
  background-color: #70CBF4;
}

.cover-fit {
  object-fit: cover;
}

.w-rec {
  width: 15.625rem;
}

.h-rec {
  height: 7.8125rem;
}

.errors {
  color: #c02;
}

.error-message {
  color: #cc0022;
  font-weight: bold;
  font-size: 1rem;
}

.slider {
  background-color: transparent;
}

.center-vertical {
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
}
.center-vertical-div{
  margin: 0;
  position: fixed;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* rating */
.rating{
  padding-bottom: 10px;
  font-size: 27pt;
}

@media screen and (max-width: 639px) {
  .rating {
    font-size: 20pt;
  }

}

.feedbackTitle {
  font-size: x-large;
  margin-bottom: 0.3em;
}

.feedbackDescription {
  font-size: medium;
  margin-bottom: 0.3em;
}

.feedbackForm{
  min-height: initial;
  transform: initial;
}

.feedbackResponseIcon {
  font-size: 27pt
}

.feedbackResponse{
  text-align: left;
  padding-top: 10px;
  padding-right: 10px;
  margin-bottom: 1em;
}


.feedbackFieldTitle{
  padding-left: 1px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.feedbackValueSelectorMissing {
  margin-bottom: 0;
}

.feedbackMandatoryMissing {
  color: red;
  margin-bottom: 10px;
}

/* rating */


.tui_ValueSelector {
  background: url(./resources/components/selector/tui_value_selector_background.png) no-repeat right #dfdfdf;
  border-radius: 5px;
  border: 1pt solid gray;
  font-size: 12pt;
  cursor: pointer;
  color: black !important;
  max-width: 300px;
  display: block;
}

/* basket, confirmation pages */
@media screen and (min-width: 40rem) {
  .checkout-container {
    max-width: 720px;
    margin: 0 auto;
  }

  .checkout-container input {
    max-width: 300px;
  }
}

/* hack due to missing padding classes with breakpoints */
@media screen and (min-width: 64rem) {
  .large-padding-right-1 {
    padding-right: 1rem;
  }
}

/* excursion image gallery */
.image-gallery-content {
  width: 100%;
}

.image-gallery-content button:focus {
  outline: 0;
}

.excursion-image-gallery {
    margin-top: -1rem;
    display: flex;
    align-items: center;
}
@media screen and (max-width: 40em) {
  .excursion-image-gallery {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
    margin-bottom: 0.5rem;
  }

  .excursion-image-gallery.full-screen-content {
    margin-top: 0;
  }
}

@media screen and (min-width: 40em) {
  .excursion-image-gallery {
    margin-top: 0;
  }
}

/* info box for cms content */
.content-infobox {
  max-width: 350px;
}

.content-infobox > div:nth-child(1) {
  background: #70cbf4;
  padding: 10px;
  text-transform: uppercase;
}

.content-infobox > div:nth-child(2) {
  background: white;
  color: #0A0A0A;
  padding: 10px;
}

.content-infobox ul {
  margin-bottom: 0;
}

.content-infobox ul.cols-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-position: inside;
  margin: 0;
}

.content-infobox ul.cols-2 li {
  width: 48%;

}

.errors {
  color: #c02;
}

.audio-description, .video-description {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-size: large;
}

.content-video-item {
  width: 100%;
}

.vod-episode-infocontainer {
  float: left;
}

@media screen and (min-width: 640px) {
  .vod-episode-infocontainer {
    padding-left: 1.5em;
  }
}

.content-icon {
  flex-shrink: 0;
}


/* Opacity */
.opacity-5 {
  opacity: 0.5;
}

.flex {
  display: flex;
}

.itinerary-timeline {
  position: relative;
}

.itinerary-timeline:before {
  content: '';
  left: 8px;
  top: 8px;
  position: absolute;
  border-left: 2px #092a5e dotted;
  height: calc(100% - 30px);
  transform: translateX(-50%);
  z-index: 1;
}

.z-5 {
  z-index: 5;
}

.uppercase {
  text-transform: uppercase;
}

.border-top-blue {
  border-top: 2px solid #1E2C5C;
}

.pt-2 {
  padding-top: 0.5rem;
}

/* List Style for Problem Received */

.problemReceived {
  list-style-type: none;
  margin-left: .25rem;
  margin-bottom: .25rem;
  display: inline-block;
}

.problemReceived li {
  position: relative;
  margin: 0;
  padding-bottom: 0.1em;
  padding-left: 25px;
}

.problemReceived li:before {
  background-color: rgb(36, 79, 138);
  width: 2px;
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 5px;
}

.problemReceived li::after {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 12px;
  height: 12px;
}

.problemReceived li:first-child::after {
  content: '';
  width: 20px;
  height: 20px;
  left: -3.5px;
  background-color: #FFFFFF;
  border-radius: 50%;
  top: 0;
  color: #FFFFFF;
  background-image: url("./resources/components/problemTracking/info-circle.svg");
}

.problemReceived li:first-child:before { top: 15px;  }

.problemReceived li:last-child:before { height: 6px; }

.problemReceived li:last-child:after {
  background-color: #FFFFFF;
  width: 20px;
  height: 20px;
  display: inline;
  border-radius: 50%;
  left: -3.5px;
  background-image: url("./resources/components/problemTracking/1.svg");
}

.problemReceived .li2Step:last-child:after {
  background-color: #FFFFFF;
  width: 20px;
  height: 20px;
  display: inline;
  border-radius: 50%;
  left: -3.5px;
  background-image: url("./resources/components/problemTracking/2.svg");
}

.problemReceived .resolved:last-child:after {
  background-color: #FFFFFF;
  width: 20px;
  height: 20px;
  display: inline;
  border-radius: 50%;
  left: -3.5px;
  background-image: url("./resources/components/problemTracking/check-circle.svg");
}

.list-style-none {
  list-style-type: none;
}

/* MANUAL fixes/additions for presale excursion styles */

*, ::before, ::after {
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
}


 a.action-button:hover, a.action-button:focus {
  color: white;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-no-underline {
  text-decoration: none;
}

.justify-between {
  justify-content: space-between;
}

.sm-hidden {
  display: none;
}


@media screen and (min-width: 600px) {
  .sm-hidden {
    display: inline-block;
  }
}

.bg-yellow {
  background: #fcb712;
}



/* STYLES for excursions generated from PRESALES project using purgecss */
.container {
  padding: 0 0.5rem
}

@media (min-width: 580px) {
  .container {
    padding: 0 1rem
  }
}

* {
  min-height: 0;
  min-width: 0;
}

.container-2-1 {
  height: 0;
  padding-bottom: 50%;
  overflow: hidden;
  position: relative;
}

.container-2-1-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cover-fit {
  object-fit: cover;
}

.action-button {
  text-transform: uppercase;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: .25rem;
  font-size: 1.125rem;
  line-height: 1.333;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2)
}

.action-button-enabled {
  color: var(--action-btn-color);
  background-color: var(--action-btn-bg);
  cursor: pointer
}

/* headers */

h2.excursion {
  text-transform: uppercase;
  color: var(--excursion-h2-color);
  font-size: 1.375rem;
  font-weight: 400
}

h1.excursion {
  color: var(--excursion-h1-color);
  font-weight: 400;
  font-size: 1.875rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

/* generic form */

/* textarea */

/* checkboxes */

/* radio buttons*/

@media (min-width: 580px)  {
  .container {
    max-width: 580px;
  }
}

@media (min-width: 760px)  {
  .container {
    max-width: 760px;
  }
}

@media (min-width: 1080px)  {
  .container {
    max-width: 1080px;
  }
}

.bg-grey-lighter{
  background-color: var(--bg-grey-lighter);
}

.bg-white{
  background-color: var(--bg-white);
}

.bg-primary-light{
  background-color: var(--bg-primary-light);
}

.bg-primary-lighter{
  background-color: var(--bg-primary-lighter);
}

.bg-primary-lightest{
  background-color: var(--bg-primary-lightest);
}

.bg-smoke-darker{
  background-color: var(--bg-smoke-darker);
}

.border-white{
  border-color: var(--border-white);
}

.border-primary{
  border-color: var(--border-primary);
}

.border-primary-light{
  border-color: var(--border-primary-light);
}

.border-red{
  border-color: var(--border-primary-red);
}

.border-dotted{
  border-style: dotted;
}

.border{
  border-width: 1px;
}

.border-l-2{
  border-left-width: 2px;
}

.border-t{
  border-top-width: 1px;
}

.border-r{
  border-right-width: 1px;
}

.border-b{
  border-bottom-width: 1px;
}

.cursor-pointer{
  cursor: pointer;
}

.block{
  display: block;
}

.inline-block{
  display: inline-block;
}

.inline{
  display: inline;
}

.flex{
  display: flex;
}

.hidden{
  display: none;
}

.flex-col{
  flex-direction: column;
}

.flex-wrap{
  flex-wrap: wrap;
}

.items-center{
  align-items: center;
}

.self-end{
  align-self: flex-end;
}

.flex-1{
  flex: 1 1 0%;
}

.float-right{
  float: right;
}

.font-bold{
  font-weight: 700;
}

.h-4{
  height: 1rem;
}

.h-6{
  height: 1.5rem;
}

.h-8{
  height: 2rem;
}

.text-base{
  font-size: 1rem;
}

.text-lg{
  font-size: 1.125rem;
}

.text-xl{
  font-size: 1.25rem;
}

.text-2xl{
  font-size: 1.375rem;
}

.text-3xl{
  font-size: 1.5rem;
}

.text-4xl{
  font-size: 1.875rem;
}

.leading-normal{
  line-height: 1.5;
}

.m-2{
  margin: 0.5rem;
}

.m-auto{
  margin: auto;
}

.my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4{
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-8{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-auto{
  margin-left: auto;
  margin-right: auto;
}

.mt-2{
  margin-top: 0.5rem;
}

.mr-2{
  margin-right: 0.5rem;
}

.mb-2{
  margin-bottom: 0.5rem;
}

.mt-4{
  margin-top: 1rem;
}

.mr-4{
  margin-right: 1rem;
}

.mb-4{
  margin-bottom: 1rem;
}

.mt-6{
  margin-top: 1.5rem;
}

.mb-6{
  margin-bottom: 1.5rem;
}

.mt-8{
  margin-top: 2rem;
}

.mb-8{
  margin-bottom: 2rem;
}

.overflow-auto{
  overflow: auto;
}

.p-2{
  padding: 0.5rem;
}

.p-3{
  padding: 0.75rem;
}

.p-4{
  padding: 1rem;
}

.py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-8{
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pt-2{
  padding-top: 0.5rem;
}

.pb-2{
  padding-bottom: 0.5rem;
}

.pt-4{
  padding-top: 1rem;
}

.pb-4{
  padding-bottom: 1rem;
}

.pr-4{
  padding-right: 1rem;
}

.fixed{
  position: fixed;
}

.absolute{
  position: absolute;
}

.relative{
  position: relative;
}

.inset-0{
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.top-0{
  top: 0;
}

.right-0{
  right: 0;
}

.bottom-0{
  bottom: 0;
}

.fill-current{
  fill: currentColor;
}

.stroke-current{
  stroke: currentColor;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.text-grey-darkest{
  color: var(--text-grey-darkest);
}

.text-grey-darker{
  color: var(--text-grey-darker);
}

.text-white{
  color: var(--text-white);
}

.text-primary-dark{
  color: var(--text-primary-dark);
}

.text-primary{
  color: var(--text-primary);
}

.text-primary-light{
  color: var(--text-primary-light);
}

.text-red{
  color: var(--text-primary-red);
}

.uppercase{
  text-transform: uppercase;
}

.capitalize{
  text-transform: capitalize;
}

.underline{
  text-decoration: underline;
}

.hover\:underline:hover{
  text-decoration: underline;
}

.select-none{
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.align-middle{
  vertical-align: middle;
}

.align-bottom{
  vertical-align: bottom;
}

.w-4{
  width: 1rem;
}

.w-6{
  width: 1.5rem;
}

.w-8{
  width: 2rem;
}

.w-1\/2{
  width: 50%;
}

.w-full{
  width: 100%;
}

.z-20{
  z-index: 20;
}

.z-50{
  z-index: 50;
}

@-webkit-keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse{
  50%{
    opacity: .5;
  }
}

@keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    -webkit-transform: none;
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.excursion-filters-container {
  background-color: var(--filters-container-bg);
}

@media (min-width: 580px){

  .sm\:flex{
    display: flex;
  }

  .sm\:flex-row-reverse{
    flex-direction: row-reverse;
  }

  .sm\:justify-around{
    justify-content: space-around;
  }

  .sm\:mt-0{
    margin-top: 0;
  }

  .sm\:p-4{
    padding: 1rem;
  }

  .sm\:px-0{
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-4{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:pt-4{
    padding-top: 1rem;
  }

  .sm\:pr-4{
    padding-right: 1rem;
  }

  .sm\:w-1\/2{
    width: 50%;
  }

  .sm\:w-1\/3{
    width: 33.33333%;
  }
}

@media (min-width: 760px){

  .md\:max-w-md{
    max-width: 45rem;
  }

  .md\:p-4{
    padding: 1rem;
  }

  .md\:w-1\/3{
    width: 33.33333%;
  }

  .md\:w-2\/3{
    width: 66.66667%;
  }

  .md\:text-4xl {
    font-size: 1.875rem;
  }
}

@media (min-width: 1080px){

  .xl\:flex-grow{
    flex-grow: 1;
  }

  .xl\:max-w-xl{
    max-width: 60rem;
  }

  .xl\:w-1\/4{
    width: 25%;
  }

  .xl\:w-3\/4{
    width: 75%;
  }
}

/* Print styles */
@media print {
  .print-block {
    display: block;
  }

  .print-padding-0 {
    padding: 0;
    margin: 0;
  }
}